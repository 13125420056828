import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLoading,
  IonPage,
  IonProgressBar,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import { BigNumber, ContractTransaction, ethers } from "ethers";
import { arrowBack, sparklesOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  gql,
  useQuery,
} from "@apollo/client";
import { useAccount, useBlockNumber, useSigner } from "wagmi";
import { Erc721__factory } from "../types/ethers-contracts/factories/Erc721__factory";
import { Erc721 } from "../types/ethers-contracts/Erc721";
import { Erc20__factory } from "../types/ethers-contracts/factories/Erc20__factory";
import { config } from "process";
import { Potionpalace } from "../types/ethers-contracts/Potionpalace";
import { NFTImage } from "../components/NFTImage";
import { PromiseOrValue } from "../types/ethers-contracts/common";
import { usePaintSwap } from "../hooks/usePaintSwap";
import { Potionpalace__factory } from "../types/ethers-contracts/factories/PotionPalace__factory";
import LPStaking from "./LPStaking";
const stakingContractAddress = "0xCE602B8734CbACa42fCe35BcCB242A98b41a5Ed6";
const nftContractAddress = "0x71909A03f9076c6330EeD80d34196E6498d2C921";

export const GET_GATE_TRAFFIC = gql`
  query getTraffic {
    magepunkEntereds(first: 1000) {
      id
      staker
      magePunkId
      blockNumber
    }
    magepunkExiteds(first: 1000) {
      id
      staker
      magePunkId
      blockNumber
    }
  }
`;
export const StakedCollectionInventory: React.FC<{}> = () => {
  const [supply, setSupply] = useState<number | undefined>(700);
  const { address: account } = useAccount();
  const { data: signer } = useSigner();
  const { data: blockNumber } = useBlockNumber();
  const [magePunkBrewingStarted, setBrewStartBlock] =
    useState<Record<string, number>>();
  const [myPunks, setMyPunks] = useState<number[]>([]);
  const [approved, setApproved] = useState<boolean>(false);
  const [totalPotionsBrewed, setTotalPotions] = useState<number>(0);
  const [potionPerBlock, setPotionPerBlock] = useState<BigNumber | undefined>(
    BigNumber.from(100000000000)
  );
  const [status, setStatus] = useState<"idle" | "transacting">();
  const calcPotionEarned = useCallback(
    (id: number) => {
      if (
        typeof potionPerBlock === "undefined" ||
        typeof blockNumber === "undefined" ||
        typeof magePunkBrewingStarted === "undefined"
      ) {
        return "??";
      }
      if (
        blockNumber === 0 ||
        magePunkBrewingStarted[id] === 0 ||
        typeof magePunkBrewingStarted[id] === "undefined"
      ) {
        return "?";
      }
      return ethers.utils.formatEther(
        potionPerBlock.mul(blockNumber - magePunkBrewingStarted[id])
      );
    },
    [potionPerBlock, magePunkBrewingStarted, blockNumber]
  );

  const calcAllPotionEarned = useCallback(
    (ids: number[]) => {
      return ids
        .map((x) => calcPotionEarned(x))
        .map(parseFloat)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    [calcPotionEarned]
  );

  useEffect(() => {
    const nftContract: Erc721 | undefined | null =
      signer && Erc721__factory.connect(nftContractAddress, signer);
    account &&
      nftContract
        ?.isApprovedForAll(account, stakingContractAddress)
        .then((res) => {
          setApproved(res);
        })
        .catch(() => {
          console.log("Approval request error");
        });
  }, [account, signer]);

  useEffect(() => {
    if (!account || !signer) {
      return;
    }
    const nftContract: Potionpalace | undefined =
      signer && Potionpalace__factory.connect(nftContractAddress, signer);
    account &&
      nftContract &&
      nftContract
        .potionPerBlock()
        .then((res: any) => {
          setPotionPerBlock(res);
        })
        .catch((e: any) => {
          console.log("WTF", e);
        });
  }, [account]);
  useEffect(() => {}, [account, supply]);
  const { data, refetch } = useQuery(GET_GATE_TRAFFIC);

  useEffect(() => {
    const info = data as any;
    if (info && account && info.magepunkEntereds) {
      const { inside, outside } = processMageEvents(
        info.magepunkEntereds,
        info.magepunkExiteds,
        account
      );
      const stakingContract: Potionpalace | undefined | null =
        signer && Potionpalace__factory.connect(stakingContractAddress, signer);
      inside.forEach((id: PromiseOrValue<ethers.BigNumberish>) => {
        stakingContract
          ?.receipt(id)
          .then(({ arcturianId, potionBrewedBlock, owner }) => {
            if (owner === account) {
              setMyPunks((x) => [...x, arcturianId.toNumber()]);
            }
            setBrewStartBlock((x) => ({
              ...x,
              [arcturianId.toNumber()]: potionBrewedBlock.toNumber(),
            }));
          });
      });
    }
  }, [data, account, signer]);
  const { getFallbackImage } = usePaintSwap();
  return (
    <>
      {signer &&
        myPunks.map((id) => (
          <IonCol key={id} size={"2"}>
            <NFTImage
              onClick={() => {
                Potionpalace__factory.connect(stakingContractAddress, signer)[
                  "exitPalace(uint256)"
                ](id.toString());
              }}
              src={getFallbackImage(nftContractAddress, id.toString())}
            />
          </IonCol>
        ))}
      <IonCol size="12">
        <IonProgressBar value={calcAllPotionEarned(myPunks)} />
        {myPunks && (
          <IonButton
            size="large"
            expand="full"
            color="tertiary"
            onClick={() => {
              const Potionpalace: Potionpalace | undefined | null =
                signer &&
                Potionpalace__factory.connect(stakingContractAddress, signer);
              Potionpalace &&
                Potionpalace.collectAll(myPunks).then(() => {
                  setStatus("idle");
                });
            }}
          >
            Collect {calcAllPotionEarned(myPunks)} Potion ⚗️
          </IonButton>
        )}
      </IonCol>
    </>
  );
};

export const UnStakedCollectionInventory: React.FC<{}> = ({}) => {
  const [supply, setSupply] = useState<number | undefined>(0);
  const { address: account } = useAccount();
  const { data: signer } = useSigner();

  const [magePunkOwners, setMagePunkOwners] =
    useState<Record<string, string>>();
  const [myPunks, setMyPunks] = useState<number[]>([]);
  const [approved, setApproved] = useState<boolean>(false);
  const [transacting, setTransacting] = useState<boolean>(false);

  useEffect(() => {
    console.log("ok");
    const endpoint =
      "https://api.paintswap.finance/v2/userNFTs?numToSkip=0&numToFetch=20&user=" +
      account +
      "&collections%5B0%5D=" +
      nftContractAddress +
      "&orderBy=lastTransferTimestamp&orderDirection=desc";
    if (account) {
      fetch(endpoint).then((res) => {
        res.json().then(({ nfts }) => {
          console.log(nfts);
          setMyPunks(nfts.map((x: any) => parseInt(x.tokenId)));
        });
      });
    }
  }, [account]);
  useEffect(() => {
    if (!signer || !account) {
      return;
    }
    const nftContract: Erc721 | undefined =
      signer && Erc721__factory.connect(nftContractAddress, signer);
    account &&
      nftContract
        ?.isApprovedForAll(account, stakingContractAddress)
        .then((res) => {
          setApproved(res);
          console.log("approved", res);
        });
    account && setSupply(700);
  }, [account, signer]);

  const fallbackImage = usePaintSwap((x) => x.getFallbackImage);
  return (
    <>
      {signer &&
        myPunks.map((id) => (
          <IonCol key={id} size={"2"}>
            <NFTImage
              onClick={() => {
                Potionpalace__factory.connect(
                  stakingContractAddress,
                  signer
                ).enterPalace([id]);
              }}
              src={fallbackImage(nftContractAddress, id.toString())}
            />
          </IonCol>
        ))}
      <IonCol size="12">
        {!approved && (
          <IonButton
            onClick={() => {
              const nftContract: Erc721 | undefined | null =
                signer && Erc721__factory.connect(nftContractAddress, signer);
              nftContract &&
                nftContract
                  .setApprovalForAll(stakingContractAddress, true)
                  .then(() => {});
            }}
          >
            Approve Palace
          </IonButton>
        )}

        {myPunks && myPunks.length > 0 && (
          <IonButton
            color="tertiary"
            onClick={() => {
              const Potionpalace: Potionpalace | undefined | null =
                signer &&
                Potionpalace__factory.connect(stakingContractAddress, signer);
              Potionpalace &&
                Potionpalace.enterPalace(myPunks).then(() => {
                  setTransacting(true);
                });
            }}
          >
            Stake all
          </IonButton>
        )}
        {transacting && <IonLoading isOpen={true} />}
      </IonCol>
    </>
  );
};

export const Staking: React.FC = () => {
  const { address: account } = useAccount();
  const { data: signer } = useSigner();
  const [potion, setPotion] = useState<BigNumber | undefined>();
  const [totalSupply, setTotalSupply] = useState<BigNumber | undefined>();
  const [stakeTransaction, setStakeTransaction] = useState<
    ContractTransaction | undefined
  >();

  useEffect(() => {
    const nftContract: Erc721 | undefined | null =
      signer && Erc721__factory.connect(nftContractAddress, signer);

    nftContract &&
      nftContract.on(
        nftContract.filters["Transfer(address,address,uint256)"](
          account,
          stakingContractAddress,
          null
        ),
        (from, to, amount, event) => {
          window.document.location.reload();
        }
      );
    nftContract &&
      nftContract.on(
        nftContract.filters["Transfer(address,address,uint256)"](
          stakingContractAddress,
          account,
          null
        ),
        (from, to, amount, event) => {
          window.document.location.reload();
        }
      );
  }, [account, signer]);

  const client = new ApolloClient({
    uri: "https://api.thegraph.com/subgraphs/name/lilesper/potion-palace-mages",
    cache: new InMemoryCache(),
  });

  return (
        <IonContent
          style={{
            backgroundImage:
              "https://155691471-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FBR2rsimle5PQluUCZb4h%2Fuploads%2FkjP7Qx8yVUR77Yh3zIwE%2Fpotion.png?alt=media&token=96eae289-93ec-4e35-b00e-6f462d91d95b",
          }}
        >
      <ApolloProvider client={client}>
          <IonGrid>
            <IonRow>
<IonCol size='12'>

              <LPStaking />
              </IonCol>
            </IonRow>
            <IonRow>
                <IonItem>NFT Staking:</IonItem>

                </IonRow>
            <IonRow>
              <UnStakedCollectionInventory />
              <StakedCollectionInventory />
              <ArcturianStakedCollectionInventory />
            </IonRow>
          </IonGrid>
          </ApolloProvider>
        </IonContent>
  );
};

export default Staking;

interface MageEvent {
  magePunkId: number;
  blockNumber: number;
  staker: string;
}
export function processMageEvents(
  enteringEvents: MageEvent[],
  leavingEvents: MageEvent[],
  owner: string
): { inside: number[]; outside: number[] } {
  let inside: number[] = [];
  let outside: number[] = [];

  // Iterate through the entering events and find the corresponding leaving events
  for (const enteringEvent of enteringEvents.filter(
    (x) => x.staker.toLowerCase() === owner.toLowerCase()
  )) {
    const leavingEvent = leavingEvents
      .filter((event) => event.magePunkId === enteringEvent.magePunkId)
      .sort((x, y) => x.blockNumber - y.blockNumber)[0];

    // Check if there is a leaving event and compare the block numbers
    if (leavingEvent && leavingEvent.blockNumber > enteringEvent.blockNumber) {
      outside.push(enteringEvent.magePunkId);
    } else {
      inside.push(enteringEvent.magePunkId);
    }
  }

  // Remove duplicates from the inside and outside arrays
  const uniqueInside = Array.from(new Set(inside));
  const uniqueOutside = Array.from(new Set(outside));

  return { inside: uniqueInside, outside: uniqueOutside };
}

export const ArcturianStakedCollectionInventory: React.FC = () => {
  const arcStakingContractAddress =
    "0x746b38f54D68342CD0ce7063B12B1e1a1AeFe85e";
  const arcturiansAddress = "0x4af7ad773e67eCF00299F7585caCc8ddbB62DC5C";

  const [supply, setSupply] = useState<number | undefined>(100);
  const { data: blockNumber } = useBlockNumber();
  const [magePunkBrewingStarted, setBrewStartBlock] =
    useState<Record<string, number>>();
  const [myPunks, setMyPunks] = useState<number[]>([]);
  const [approved, setApproved] = useState<boolean>(false);
  const [potionPerBlock, setPotionPerBlock] = useState<BigNumber | undefined>(
    BigNumber.from(170000000000)
  );
  const { data: signer } = useSigner();
  const { address: account } = useAccount();
  const [status, setStatus] = useState<"idle" | "transacting">();
  const calcPotionEarned = useCallback(
    (id: number) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (
        typeof potionPerBlock === "undefined" ||
        typeof blockNumber === "undefined" ||
        typeof magePunkBrewingStarted === "undefined"
      ) {
        return "?";
      }
      if (
        blockNumber === 0 ||
        magePunkBrewingStarted[id] === 0 ||
        typeof magePunkBrewingStarted[id] === "undefined"
      ) {
        return "?";
      }
      return ethers.utils.formatEther(
        potionPerBlock.mul(blockNumber - magePunkBrewingStarted[id])
      );
    },
    [potionPerBlock, magePunkBrewingStarted, blockNumber]
  );

  const calcAllPotionEarned = useCallback(
    (ids: number[]) => {
      return ids
        .map((x) => calcPotionEarned(x))
        .map(parseFloat)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    [calcPotionEarned]
  );

  useEffect(() => {
    if (!signer || !account) {
      return;
    }
    const nftContract: Erc721 = Erc721__factory.connect(
      arcturiansAddress,
      signer
    );
    nftContract
      ?.isApprovedForAll(account, arcStakingContractAddress)
      .then((res) => {
        setApproved(res);
      })
      .catch(() => {
        console.log("Approval request error");
      });
  }, [account, signer]);

  useEffect(() => {
    if (!signer || !account) {
      return;
    }

    const nftContract: Potionpalace = Potionpalace__factory.connect(
      arcStakingContractAddress,
      signer
    );
    Array.from(Array(supply)).forEach(async (x, i) => {
      const id = i + 1;
      account &&
        signer &&
        nftContract
          ?.receipt(id)
          .then(([magePunkId, startedBrewing, owner]) => {
            if (owner === account) {
              setMyPunks((x) => {
                if (x.includes(id)) {
                  return x;
                }
                return [...x, id];
              });
            }
            startedBrewing.toNumber() > 0 &&
              setBrewStartBlock((x) => ({
                [id]: startedBrewing.toNumber(),
                ...x,
              }));
          })
          .catch(() => {
            console.log(
              "Error gettings brewed gwei of potion or staking receipt"
            );
          });
    });
  }, [account, signer, supply]);
  const { getFallbackImage } = usePaintSwap();
  console.log(myPunks);
  return (
    <>
      {myPunks.map((id) => (
        <IonCol key={id} size={"2"}>
          <NFTImage src={getFallbackImage(arcturiansAddress, id.toString())} />
        </IonCol>
      ))}
      <IonCol size="12">
        <IonProgressBar value={calcAllPotionEarned(myPunks)} />
        {myPunks && signer && (
          <IonButton
            size="large"
            expand="full"
            color="tertiary"
            onClick={() => {
              const Potionpalace: Potionpalace = Potionpalace__factory.connect(
                arcStakingContractAddress,
                signer
              );
              Potionpalace &&
                Potionpalace.collectAll(myPunks).then(() => {
                  setStatus("idle");
                });
            }}
          >
            Collect {calcAllPotionEarned(myPunks)} Potion ⚗️
          </IonButton>
        )}
      </IonCol>
    </>
  );
};
