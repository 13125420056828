import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonFabButton,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Tarot__factory } from "../types/ethers-contracts";
import { BigNumber } from "ethers";
import { useAccount, useSigner } from "wagmi";
import { useTransactionModal } from "../hooks/useTransactionModal";
import tarot_db from "../assets/tarot_info.json";
import { timeSince } from "./Swaps";
import { parseEther } from "ethers/lib/utils.js";
import { closeCircle, closeOutline, informationOutline } from "ionicons/icons";
const functionNames: Record<string, string> = {
  "1": "luck",
  "3": "pastPresentFuture",
  "5": "success",
  "6": "love",
  "7": "career",
  "8": "guidance",
  "10": "celticCross",
};
const cardNames: Record<string, string[]> = {
  "1": ["luck"],
  "3": ["past", "present", "future"],
  "5": [
    "true colors",
    "challenge",
    "hidden factor",
    "new energy",
    "requirement",
  ],
  "6": [
    "current feelings",
    "partners feelings",
    "connection card",
    "strength",
    "weakness",
    "true love",
  ],
  "7": [
    "is your job ideal?",
    "boost your career",
    "unchangable truth",
    "skills for promotion",
    "how to improve",
    "past mistakes",
    "final outcome",
  ],
  "8": [
    "main concern",
    "motivation",
    "worries",
    "ignorant",
    "advice",
    "guide",
    "how to deal",
    "final outcome",
  ],
  "10": [
    "current situation",
    "basic challenge",
    "subconsious influence",
    "resources",
    "prologue",
    "negativity",
    "attitude",
    "energy",
    "desire & fear",
    "final outcome",
  ],
};

interface pastReading {
  cards: number[];
  timestamp: string;
}
export const TarotPage: React.FC = () => {
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const [reveal, setReveal] = useState<Array<number>>([]);
  const [readingType, setReadingType] = useState<number>();
  const [selected, setSelected] = useState<number>(-1);
  const [history, setHistory] = useState<pastReading[]>([]);
  const { post } = useTransactionModal();
  const selectedCard = tarot_db.tarot_interpretations[selected - 1];
  useEffect(() => {
    if (!signer) {
      return;
    }

    const instance = Tarot__factory.connect(
      "0x8928b74966ce74c12fa4a46fbf558d99841446aa",
      signer
    );
    fetch(
      "https://api.ftmscan.com/api?module=logs&action=getLogs&fromBlock=28731619&toBlock=100000000000000&address=0x8928b74966ce74c12fa4a46fbf558d99841446aa&apikey=NKRH3Y8IIRGUAKSGDDA7J6JQXH9GBEM5KF"
    )
      .then((res) => {
        console.log(res);
        if (!address) {
          return;
        }
        res.json().then((response) => {
          const info = response.result.filter((x: any) =>
            (x.topics[1] as string)
              .toLowerCase()
              .includes(address.slice(2, address.length + 1).toLowerCase())
          );
          console.log(info);
          const iface = Tarot__factory.createInterface();

          const past = info.map((x: any) => ({
            cards: iface.decodeEventLog("Reading", x.data)[1],
            timestamp: timeSince(BigNumber.from(x.timeStamp).toNumber()),
          }));
          setHistory(past);
        });
      })
      .catch((e) => {
        console.log(e);
      });

    instance.owner().then((owner) => {
      if (address === owner) {
        (window as any).withdraw = instance.withdraw;
      }
    });
  }, [address, signer]);
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonContent>
      <IonCard>
        {reveal.length === 0 && (
          <IonItem color={"tertiary"} fill="outline">
            <IonSelect
              value={readingType}
              mode="ios"
              interface="action-sheet"
              onIonChange={({ detail }) => {
                setReadingType(detail.value);
              }}
              color="tertiary"
              placeholder="Select Reading type"
            >
              <IonSelectOption value={1}>Luck</IonSelectOption>
              <IonSelectOption value={3}>Past Present Future</IonSelectOption>
              <IonSelectOption value={5}>Success</IonSelectOption>
              <IonSelectOption value={6}>Love</IonSelectOption>
              <IonSelectOption value={7}>Career</IonSelectOption>
              <IonSelectOption value={8}>Spiritual Guidance</IonSelectOption>
              <IonSelectOption value={10}>Celtic Cross</IonSelectOption>
            </IonSelect>{" "}
          </IonItem>
        )}
        {reveal.length > 0 && readingType && (
          <IonItem>{functionNames[readingType]} reading:</IonItem>
        )}
        {signer && reveal.length === 0 && readingType && (
          <IonButton
            expand="full"
            onClick={() => {
              const instance = Tarot__factory.connect(
                "0x8928b74966ce74c12fa4a46fbf558d99841446aa",
                signer
              );
              (instance.functions as any)
                [functionNames[readingType.toString()] as string]({
                  value: BigNumber.from(10).pow(18).mul(readingType),
                  gasLimit: BigNumber.from(1).mul(BigNumber.from(10).pow(6)),
                })
                .then((tx: any) => {
                  post(tx, functionNames[readingType] + " reading", () => {});
                });
              instance.on(
                instance.filters["Reading(address,uint256[])"](address, null),
                (_, cards) => {
                  setReveal(cards.map((x: BigNumber) => x.toNumber()));
                  // setFlipped(cards.map((x: BigNumber) => false));
                }
              );
            }}
          >
            Request Reading 🔮
          </IonButton>
        )}
        <IonModal
          ref={modal}
          isOpen={selected !== -1}
          onWillDismiss={() => {
            setSelected(-1);
          }}
        >
          <IonContent>
            {selectedCard && (
              <IonCard>
                <IonCardHeader color="tertiary">
                  <IonToolbar color='tertiary'>
                    <IonCardTitle>{selectedCard.name}</IonCardTitle>
                    <IonCardSubtitle>
                      {readingType &&
                        cardNames[readingType][reveal.indexOf(selected)]}
                    </IonCardSubtitle>
                    <IonButtons slot="end">
                      <IonButton color={'dark'}
                        onClick={() => {
                          modal.current?.dismiss();
                        }}
                      >
                        <IonIcon icon={closeOutline} />
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonCardHeader>
                <IonCardContent>
                  {/* <IonImg style={{backgroundColor:"white",height:300,width:200}} src={"/assets/tarot/" + selected + ".svg"} /> */}
                  {selectedCard.fortune_telling.map((x) => (
                    <div>{x}</div>
                  ))}
                  {selectedCard.keywords.map((x) => (
                    <IonChip>{x}</IonChip>
                  ))}
                  <IonItem>Meanings:</IonItem>
                  <IonBadge color="light">Light</IonBadge>
                  {selectedCard.meanings.light.map((x) => (
                    <IonRow>{x}</IonRow>
                  ))}
                  <IonBadge color="light">Shadow</IonBadge>
                  {selectedCard.meanings.shadow.map((x) => (
                    <IonRow>{x}</IonRow>
                  ))}
                </IonCardContent>
              </IonCard>
            )}{" "}
          </IonContent>
        </IonModal>
        <IonRow>
          {readingType &&
            reveal?.map((revealedCard, i) => (
              <IonCard
                onClick={() => {
                  setSelected(revealedCard);
                  // setFlipped((x) => {
                  //   const a = x;
                  //   a?.splice(i, 1, !flipped[i]);
                  //   return a;
                  // });
                  // console.log(flipped);
                }}
                color={"tertiary"}
                style={{
                  height: "436px!important",
                  width: "249px!important",
                  cursor: "pointer",
                }}
              >
                <IonCardContent>
                  <IonImg
                    style={{
                      background:
                        "radial-gradient(circle, rgba(172,150,219,1) 0%, rgba(106,100,255,1) 100%)",
                      borderRadius: 20,
                      padding: -5,
                    }}
                    src={"/assets/tarot/" + revealedCard + ".svg"}
                  />

                  <IonBadge color="tertiary">
                    <IonText color="light">{cardNames[readingType][i]}</IonText>
                  </IonBadge>
                </IonCardContent>
              </IonCard>
            ))}
        </IonRow>
      </IonCard>
      <IonList>
        {history.map(({ cards, timestamp }) => (
          <IonItem
            onClick={() => {
              console.log(cards);
              setReveal(cards);
              setReadingType(cards.length);
            }}
          >
            <IonChip color={"tertiary"}>
              {functionNames[cards.length]} on {timestamp}
            </IonChip>
          </IonItem>
        ))}
      </IonList>
    </IonContent>
  );
};
