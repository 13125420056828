import { BigNumber, ContractTransaction, Signer } from "ethers";
import { create } from "zustand";
import { fromFloat, percentFromFloat } from "./useBondingCurve";
import { PairFactory } from "../types/ethers-contracts/PairFactory";
import { PairFactory__factory } from "../types/ethers-contracts/factories/PairFactory__factory";

export type PairFactoryTemplate = {
  address: string;
  nft: string | undefined;
  assetReceiver: string | undefined;
  erc20: string | undefined;
  bondingCurve: string | undefined;
  poolType: string | undefined;
  factory: PairFactory | undefined;
  delta: number | undefined;
  fee: number | undefined;
  spotPrice: string | undefined;
  initialTokenBalance: string | undefined;
  initialIds: string[];
  pairs: string[];
  setPairs: (pairs: string[]) => void;
  onPairCreate: (address: string) => void;
  createPool: (account: string) => Promise<ContractTransaction> | undefined;
  initialize: (signer: Signer) => void;
  fetchPairs: () => void;
  setValue: (key: string, value: any) => void;
};

export const usePairFactory = create<PairFactoryTemplate>((set, store) => ({
  erc20: "fantom",
  assetReceiver: undefined,
  poolType: "1",
  bondingCurve: "0xDD62964C856445496Baaf2C4B1EC224966415aC2",
  nft: undefined,
  factory: undefined,
  delta: undefined,
  fee: undefined,
  initialTokenBalance: undefined,
  spotPrice: undefined,
  initialIds: [],
  pairs: [
    "0xf2f4ed18f728083acf0c4574323d3360eec80f97",
    "0x4f26346dcff9114778cc1a35912caa27bcfd0920",
    "0xd746596969df130e22887f17442beea07beaad14",
    "0x8e4c2c9f732bd76a97f30dea04acb64a42d26adb",
    "0x7d5af9bc1ce78b4d57074e53e7ae7227f24c54e6",
    "0x3d1067c600ce50c23776ce5e6dd70d2afa83a192",
    "0x973ea8d52402cf72f7981915c2261db3030ad0d4",
    "0x02226f50a089a24b730f3e3c8169f5f7ec183643",
    "0x212bd30a35a970ca3922babde8c09dc309abd12c",
    "0x7d5af9bc1ce78b4d57074e53e7ae7227f24c54e6",
    "0x5f7489d807da0673677de26970352087812a309d",
    "0x7e7fb1a8d9774d5180d4a529dc208622f486640e",
    "0x4B94a8BD742DAA1246cd24763b038e879460Bc49",
  ],
  address: "0x90069a0A7135108f032dBB9a0Ddfb42f4c479589",
  fetchPairs: () => {
    fetch(
      "https://api.ftmscan.com/api?module=account&action=txlistinternal&address=" +
        store().address +
        "&startblock=64019621&endblock=99999999&apikey=NKRH3Y8IIRGUAKSGDDA7J6JQXH9GBEM5KF"
    ).then((transactions) => {
      transactions.json().then((txns) => {
        const { result } = txns;
        const pairs = (result as Array<any>)
          .map((x) => x.contractAddress.toLowerCase())
          .filter((x) => x.length !== 0);
        store().setPairs(Array.from(new Set([...pairs,...store().pairs, ])));
      });
    });
  },
  setPairs: (pairs) => {
    set({ pairs });
  },
  onPairCreate: (account: string) => {},
  createPool: (account: string) => {
    if (store().erc20 !== "fantom") {
      return store().factory?.createPairERC20({
        assetRecipient:
          store().poolType === "2"
            ? "0x0000000000000000000000000000000000000000"
            : store().assetReceiver!,
        bondingCurve: store().bondingCurve!,
        delta: fromFloat(store().delta!),
        fee: store().poolType === "2" ? percentFromFloat(store().fee!) : 0,
        initialNFTIDs: store().initialIds.map(BigNumber.from),
        initialTokenBalance: fromFloat(store().initialTokenBalance!),
        nft: store().nft!,
        poolType: store().poolType!,
        spotPrice: fromFloat(store().spotPrice!),
        token: store().erc20!,
      });
    } else {
      return store().factory?.createPairETH(
        store().nft!,
        store().bondingCurve!,
        store().poolType === "2"
          ? "0x0000000000000000000000000000000000000000"
          : store().assetReceiver!,
        store().poolType!,
        fromFloat(store().delta!),
        store().poolType === "2" ? percentFromFloat(store().fee!) : 0,
        fromFloat(store().spotPrice!),
        store().initialIds.map(BigNumber.from),
        {
          value: fromFloat(store().initialTokenBalance!),
        }
      );
    }
  },
  setValue: (key, value) => {
    set({ [key]: value });
  },
  initialize: (signer) => {
    const factory = PairFactory__factory.connect(store().address, signer);

    set({ factory });
    // factory.on('NewPair', (address: string) => {
    //   set({ pairs: Array.from(new Set([...store().pairs, address])) })
    // })
  },
}));
