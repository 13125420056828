import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonItem,
    IonProgressBar,
    IonRow,
    IonText,
    IonTitle,
  } from '@ionic/react'
  import { BigNumber } from 'ethers'
  import { formatEther } from 'ethers/lib/utils.js'
  import { useEffect, useState } from 'react'
  import { useAccount, useSigner } from 'wagmi'
  import { ApproveERC20Button } from '../components/ApproveERC20Button'
  import { formatTokenPrice } from '../components/PoolList'
  import { useERC20 } from '../hooks/useERC20'
  import { usePaintSwap } from '../hooks/usePaintSwap'
  import { useTransactionModal } from '../hooks/useTransactionModal'
  import {
    FtmPotionLPAddress,
    MimPotionLPAddress,
    usePotionOracle,
  } from '../hooks/usePotionPriceOracle'
import { useMasterChef } from '../hooks/useMasterChef'
import { potionAddress } from '../components/ConnectButton'
  const getPoolApr = (
    stakingTokenPrice: BigNumber,
    rewardTokenPrice: BigNumber,
    totalStaked: BigNumber,
    tokenPerSecond: BigNumber,
  ): BigNumber => {
    const totalRewardPricePerYear = rewardTokenPrice
      .mul(tokenPerSecond)
      .mul(BigNumber.from(31536000))
    const totalStakingTokenInPool = stakingTokenPrice.mul(totalStaked)
    const apr = totalRewardPricePerYear.mul(totalStakingTokenInPool)
    return apr || BigNumber.from(0)
  }
  const voyagerAddress = '0xE786AAb7b220C1F9184ad406406806034785Fc5C'.toLowerCase()
  const LPStaking: React.FC = () => {
    const { post } = useTransactionModal()
    const potionBalance = useERC20((x) => x.balances[potionAddress])
    const potionLPBalance = useERC20((x) => x.balances[FtmPotionLPAddress])
    const potionMimLPBalance = useERC20((x) => x.balances[MimPotionLPAddress])
    const {
      potionPrice,
      ftmPrice,
      potionPriceUsd,
      initialize: initOracle,
    } = usePotionOracle()
    const { address } = useAccount()
    const { data: signer } = useSigner()
    const { fetchCollectionInfo } = usePaintSwap()
    const collection = usePaintSwap((x) =>
      x.collections.find((x) => x.address === voyagerAddress),
    )
    const inventory = usePaintSwap((x) => x.inventory[voyagerAddress])
    useEffect(() => {
      address && fetchCollectionInfo(address, [voyagerAddress])
    }, [address, fetchCollectionInfo])
    const [selectedVoyager, setSelectedVoyager] = useState<string>('')
    const {
      initialize: initMasterChef,
      instance: farm,
      pendingPotion,
      userInfo,
      potionPerSecond,
      poolInfo,
    } = useMasterChef()
    const LPPoolInfo = poolInfo['0']
    const LPAlloc = LPPoolInfo && LPPoolInfo[1]
    const LPShare = LPPoolInfo && LPPoolInfo[3]
    const stakedPotionLPBalance = userInfo && userInfo['0'] && userInfo['0'][0]
    const stakedPotionMimLPBalance = userInfo && userInfo['2'] && userInfo['2'][0]
    const potionLPReward = pendingPotion && pendingPotion['0']
    const potionMimLPReward = pendingPotion && pendingPotion['2']
    useEffect(() => {
      signer && address && initMasterChef(signer, address)
      signer && address && initOracle(signer)
    }, [address, initMasterChef, initOracle, signer])
    const potionPriceUSD = (
      ((1 / parseFloat(formatEther(ftmPrice()))) * 1) /
      parseFloat(formatEther(potionPrice()))
    )
      .toString()
      .slice(0, 5)
    const fantomPriceUsd = (1 / parseFloat(formatEther(ftmPrice())))
      .toString()
      .slice(0, 5)
    // const poolAPR =
    //   stakedPotionBalance &&
    //   potionPerSecond &&
    //   getPoolApr(
    //     potionPrice(),
    //     potionPrice(),
    //     stakedPotionLPBalance,
    //     potionPerSecond,
    //   )
    return (
        <IonCard>
          <IonCardTitle>
            <IonTitle color={'primary'}>Liquidity Staking</IonTitle>{' '}
            <IonItem>
              <IonButtons slot="start">
                <IonChip>
                  <IonAvatar>
                    <IonImg
                      src={'https://magepunks.xyz/assets/images/potion-icon.png'}
                    ></IonImg>
                  </IonAvatar>
                  <IonText color="tertiary"></IonText>
                  <IonText color={'success'}>${potionPriceUSD}</IonText>{' '}
                </IonChip>
              </IonButtons>
              <IonChip>
                <IonAvatar>
                  <IonText color="primary">
                    <IonImg src="https://assets.spooky.fi/tokens/FTM.png" />
                  </IonText>
                </IonAvatar>
                <IonText color={'success'}>${fantomPriceUsd}</IonText>
              </IonChip>
              <IonButtons slot="end">
                <IonButton
                  color={'primary'}
                  href="https://spooky.fi/#/add/0x3edA36088b931098e8E472748840b3dF78268c72/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83"
                  target="_new"
                >
                  <IonChip>
                    Make Potion LP on Spooky.fi
                    <IonAvatar>
                      <IonImg src="https://s2.coinmarketcap.com/static/img/coins/200x200/9608.png" />
                    </IonAvatar>
                  </IonChip>
                </IonButton>
              </IonButtons>
            </IonItem>
            {/* {poolAPR && formatTokenPrice(poolAPR)} */}
            {/* <IonItem> */}
            {/* </IonItem> */}
          </IonCardTitle>
          <IonCardContent>
            <IonGrid>
              {/* <IonRow>
                <IonCol>
                  {potionBalance && potionBalance.gt(BigNumber.from(10).pow(15)) && (
                    <IonItem>
                      <IonText color={'tertiary'}>
                        {potionBalance && formatTokenPrice(potionBalance)} Potion
                      </IonText>
  
                      {potionBalance && (
                        <IonProgressBar
                          color={'tertiary'}
                          value={
                            parseFloat(formatTokenPrice(potionBalance)) / 100
                          }
                        />
                      )}
                      <IonButtons slot="end">
                        {potionBalance && (
                          <ApproveERC20Button
                            amount={potionBalance}
                            contract={potionAddress}
                            operator="0x962F8eD9cF4fE67962c9CF110410eec4828b2291"
                          />
                        )}
  
                        {potionBalance && (
                          <IonButton
                            color={'tertiary'}
                            fill="solid"
                            expand="full"
                            onClick={() => {
                              farm &&
                                farm.deposit('1', potionBalance).then((tx) => {
                                  post(tx, 'Depositing', () => {})
                                })
                            }}
                          >
                            Stake Potion
                          </IonButton>
                        )}
                      </IonButtons>
                    </IonItem>
                  )}
                </IonCol>
              </IonRow> */}
             
  
              <IonRow>
                <IonCol>
                  {stakedPotionLPBalance && stakedPotionLPBalance.gt(0) && (
                    <IonItem>
                      <IonButtons>
                        <IonChip>
                          <IonAvatar>
                            <IonImg
                              src={
                                'https://magepunks.xyz/assets/images/potion-icon.png'
                              }
                            ></IonImg>
                          </IonAvatar>
                          <IonAvatar>
                            <IonImg
                              src={'https://assets.spooky.fi/tokens/FTM.png'}
                            ></IonImg>
                          </IonAvatar>
                        </IonChip>
                      </IonButtons>
                      <IonText color={'primary'}>
                        {stakedPotionLPBalance &&
                          formatTokenPrice(stakedPotionLPBalance)}
                        Potion LP
                      </IonText>
                      {stakedPotionLPBalance &&
                        stakedPotionLPBalance.gt(BigNumber.from(0)) && (
                          <IonButtons slot="end">
                            {potionLPReward && (
                              <IonButton
                                onClick={() => {
                                  farm?.deposit('0', BigNumber.from(0))
                                }}
                                color="success"
                              >
                                {formatEther(potionLPReward)} Earned
                              </IonButton>
                            )}
                            <IonButton
                              onClick={() => {
                                farm &&
                                  farm
                                    .withdraw('0', stakedPotionLPBalance)
                                    .then((tx) => {
                                      post(tx, 'Withdrawing', () => {})
                                    })
                                    .catch(() => {})
                              }}
                              color={'danger'}
                              expand="full"
                            >
                              Unstake LP
                            </IonButton>
                          </IonButtons>
                        )}
                      {stakedPotionLPBalance && (
                        <IonProgressBar
                          color={'primary'}
                          value={
                            parseFloat(formatTokenPrice(stakedPotionLPBalance)) /
                            100
                          }
                        />
                      )}
                    </IonItem>
                  )}
                  {potionLPBalance && potionLPBalance.gt(0) && (
                    <IonItem>
                      <IonButtons>
                        <IonChip>
                          <IonAvatar>
                            <IonImg
                              src={
                                'https://magepunks.xyz/assets/images/potion-icon.png'
                              }
                            ></IonImg>
                          </IonAvatar>
                          <IonAvatar>
                            <IonImg
                              src={'https://assets.spooky.fi/tokens/FTM.png'}
                            ></IonImg>
                          </IonAvatar>
                        </IonChip>
                      </IonButtons>
                      <IonText color={'primary'}>
                        {potionLPBalance && formatTokenPrice(potionLPBalance)}
                        Potion LP
                      </IonText>
  
                      {potionLPBalance && potionLPBalance.gt(BigNumber.from(0)) && (
                        <IonButtons slot="end">
                          {potionBalance && (
                            <ApproveERC20Button
                              amount={potionLPBalance}
                              contract={FtmPotionLPAddress}
                              operator="0x962F8eD9cF4fE67962c9CF110410eec4828b2291"
                            />
                          )}
                          <IonButton
                            color={'primary'}
                            fill="solid"
                            expand="full"
                            onClick={() => {
                              farm &&
                                farm.deposit('0', potionLPBalance).then((tx) => {
                                  post(tx, 'Depositing', () => {})
                                })
                            }}
                          >
                            Stake Potion LP
                          </IonButton>
                        </IonButtons>
                      )}
  
                      {potionLPBalance && (
                        <IonProgressBar
                          color={'primary'}
                          value={
                            parseFloat(formatTokenPrice(potionLPBalance)) / 100
                          }
                        />
                      )}
                    </IonItem>
                  )}
                </IonCol>
              </IonRow>
  
              <IonRow>
                <IonCol>
                  {/* {stakedPotionLPBalance && stakedPotionLPBalance.gt(0) && (
                    <IonItem>
                      <IonButtons>
                        <IonChip>
                          <IonAvatar>
                            <IonImg
                              src={
                                'https://magepunks.xyz/assets/images/potion-icon.png'
                              }
                            ></IonImg>
                          </IonAvatar>
                          <IonAvatar>
                            <IonImg
                              src={'https://assets.spooky.fi/tokens/MIM.png'}
                            ></IonImg>
                          </IonAvatar>
                        </IonChip>
                      </IonButtons>
                      <IonText color={'primary'}>
                        {stakedPotionMimLPBalance &&
                          formatTokenPrice(stakedPotionMimLPBalance)}
                        Potion MIM LP
                      </IonText>
                      {stakedPotionMimLPBalance &&
                        stakedPotionMimLPBalance.gt(BigNumber.from(0)) && (
                          <IonButtons slot="end">
                            {potionMimLPReward && (
                              <IonButton
                                onClick={() => {
                                  farm?.deposit('0', BigNumber.from(0))
                                }}
                                color="success"
                              >
                                {formatEther(potionMimLPReward)} Earned
                              </IonButton>
                            )}
                            <IonButton
                              onClick={() => {
                                farm &&
                                  farm
                                    .withdraw('0', stakedPotionMimLPBalance)
                                    .then((tx) => {
                                      post(tx, 'Withdrawing', () => {})
                                    })
                                    .catch(() => {})
                              }}
                              color={'danger'}
                              expand="full"
                            >
                              Unstake LP
                            </IonButton>
                          </IonButtons>
                        )}
                      {stakedPotionMimLPBalance && (
                        <IonProgressBar
                          color={'primary'}
                          value={
                            parseFloat(formatTokenPrice(stakedPotionMimLPBalance)) /
                            100
                          }
                        />
                      )}
                    </IonItem>
                  )} */}
                  {potionLPBalance && potionLPBalance.gt(0) && (
                    <IonItem>
                      <IonButtons>
                        <IonChip>
                          <IonAvatar>
                            <IonImg
                              src={
                                'https://magepunks.xyz/assets/images/potion-icon.png'
                              }
                            ></IonImg>
                          </IonAvatar>
                          <IonAvatar>
                            <IonImg
                              src={'https://assets.spooky.fi/tokens/FTM.png'}
                            ></IonImg>
                          </IonAvatar>
                        </IonChip>
                      </IonButtons>
                      <IonText color={'primary'}>
                        {potionLPBalance && formatTokenPrice(potionLPBalance)}
                        Potion LP
                      </IonText>
  
                      {potionLPBalance && potionLPBalance.gt(BigNumber.from(0)) && (
                        <IonButtons slot="end">
                          {potionBalance && (
                            <ApproveERC20Button
                              amount={potionLPBalance}
                              contract={FtmPotionLPAddress}
                              operator="0x962F8eD9cF4fE67962c9CF110410eec4828b2291"
                            />
                          )}
                          <IonButton
                            color={'primary'}
                            fill="solid"
                            expand="full"
                            onClick={() => {
                              farm &&
                                farm.deposit('0', potionLPBalance).then((tx) => {
                                  post(tx, 'Depositing', () => {})
                                })
                            }}
                          >
                            Stake Potion LP
                          </IonButton>
                        </IonButtons>
                      )}
  
                      {potionLPBalance && (
                        <IonProgressBar
                          color={'primary'}
                          value={
                            parseFloat(formatTokenPrice(potionLPBalance)) / 100
                          }
                        />
                      )}
                    </IonItem>
                  )}
                </IonCol>
              </IonRow>
  
              {/* <IonRow>
                {typeof inventory !== 'undefined' &&
                  inventory
                    .filter(
                      (x) =>
                        x.tokenId === selectedVoyager ||
                        selectedVoyager.length === 0,
                    )
                    .map((x, i) => (
                      <IonCol size={selectedVoyager.length === 0 ? '2' : '3'}>
                        <IonCard
                          color={
                            selectedVoyager === x.tokenId ? 'tertiary' : undefined
                          }
                          onClick={() => {
                            if (selectedVoyager !== x.tokenId) {
                              setSelectedVoyager(x.tokenId)
                            } else {
                              setSelectedVoyager('')
                            }
                          }}
                        >
                          <IonCardHeader>
                            <IonCardTitle color="tertiary">{x.name}</IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent>
                            <IonImg src={x.image || x.thumbnail} />
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    ))}
                <IonCol size="6" offset="3">
                  <IonButton
                    color={'tertiary'}
                    fill="solid"
                    expand="full"
                    onClick={() => {
  
                    }}
                  >
                    Boost Staked Potion
                  </IonButton>
  
                  <IonButton
                    color={'primary'}
                    fill="solid"
                    expand="full"
                    onClick={() => {
                      
                    }}
                  >
                    Boost Potion LP
                  </IonButton>
                </IonCol>
              </IonRow> */}
              <IonItem href="https://ftmscan.com/address/0x962f8ed9cf4fe67962c9cf110410eec4828b2291">
                Staking Contract 0x962f8ed9cf4fe67962c9cf110410eec4828b2291
              </IonItem>
              <IonItem>
                {/* <IonButton
                  color={'tertiary'}
                  fill="outline"
                  expand="full"
                  onClick={() => {
                    farm &&
                      farm.massUpdatePools().then((tx) => {
                        post(tx, 'Updating', () => {})
                      })
                  }}
                >
                  Update Pools
                </IonButton> */}
              </IonItem>
            </IonGrid>
          </IonCardContent>
        </IonCard>
    )
  }
  
  export default LPStaking
  