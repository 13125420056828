import { useEffect } from "react";
import { useAccount, useSigner } from "wagmi";
import { usePaintSwap } from "../hooks/usePaintSwap";
import { ethers } from "ethers";

export const PaintSwap: React.FC<{ children: any }> = ({ children }) => {
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const { initialize } = usePaintSwap();
  useEffect(() => {
    const fantomProvider = new ethers.providers.JsonRpcProvider(
      "https://rpc.ftm.tools/"
    );
    initialize(address || "", signer ? signer : fantomProvider);
  }, [address, initialize, signer]);
  return <>{children}</>;
};
