import { BigNumber, Signer } from 'ethers'
import { create } from 'zustand'
import { Erc20 } from '../types/ethers-contracts/Erc20'
import { Erc20__factory } from '../types/ethers-contracts/factories/Erc20__factory'

export type ERC20Template = {
  instances: Record<string, Erc20>
  balances: Record<string, BigNumber>
  initAndCheckBalance: (wallet: string, address: string, signer: Signer) => void
  initialize: ( address: string, signer: Signer|any) => void
}

export const useERC20 = create<ERC20Template>((set, store) => ({
  instances: {},
  balances: {},
  initAndCheckBalance: (wallet, address, signer) => {
    const erc20Instance = Erc20__factory.connect(address, signer)
    set({ instances: { ...store().instances, [address]: erc20Instance } })
    erc20Instance.balanceOf(wallet).then((total) => {
      console.log()
      set({ balances: { ...store().balances, [address]: total } })
    })
  },
  initialize: (address, signer) => {
    const erc20Instance = Erc20__factory.connect(address, signer)
    set({ instances: { ...store().instances, [address]: erc20Instance } })
  },
}))
