import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonProgressBar,
  IonRow,
  IonTitle,
} from '@ionic/react'
import { BigNumber } from 'ethers'
import { Fragment } from 'react'
import { useAccount } from 'wagmi'
import { usePaintSwap } from '../hooks/usePaintSwap'
import { Page } from '../pages/Page'
import { CreatePool } from './CreatePool'
import { NFTImage } from './NFTImage'
import { formatTokenPrice, PleaseConnectCard } from './PoolList'

interface ContainerProps {
  name: string
}
const flavorText: Record<string, string> = {
  Powergoools: 'powering up!',
  'Marble Maidens': 'being breathtaking',
  'Magical Goools': 'casting spells',
  'Riot Goools': 'Making Zines & Jamming',
  BITSHADOWZ: 'protecting the ftm chain',
  'Cosmic frens': 'vibing',
  'Bit Voyagers': 'Stuck in the flipside',
  'FTM Elves': 'rugging',
  'Xonas World': 'being wholesome',
  Magepunks: 'adventuring',
  Arcturians: 'being psychic',
  'Witches Oracle': 'gathering herbs',
  'Fantom Lords': 'being fierce',
  'Moon Masters': 'vibing in the moon bay',
  'Goool Bands': 'practicing for their next show',
  'Bit Daemons': 'stacking their canto validator',
  'Heroes of Luck': 'Bringing you good luck',
  '=Punks': 'Hustling them bribes',
  'Chill Pills': 'Chillin',
  BBChickens: 'backin up the beets',
  'Mummy Club': 'Longing $ftm',
  Skullys: 'Performing summoning rituals',
  'Ancestral Umans': 'Meditating',
  'Cosmic Friends': '~ Vibing ~',
  Gooolmon: '~ bein wild ~',
  'Astro Kid': '~ becoming 3D ~',
  Magicats: '~ bein spoooky ~',
  Netizens: '8bitting',
  Cyberpopgirl: 'being tottally badass',
  'kool kids fantom': 'being really really koool',
  'Acid Cat': 'Tripping',
} as Record<string, string>
export const WalletViewer: React.FC = () => {
  const { address } = useAccount()
  const {
    collections,
    fetchCollectionStats,
    fetchCollectionInfo,
    ownedCollections,
    inventory,
    getFallbackImage,
    fallback,
  } = usePaintSwap()
  if (!address) {
    return <PleaseConnectCard />
  }

  return (
    <IonContent>
      <IonCard>
        <IonCardHeader>
          <IonTitle> {address}</IonTitle>
        </IonCardHeader>
        <IonCardContent key={'wallet'}>
          <IonAccordionGroup
            multiple
            onIonChange={(e) => {
              address && fetchCollectionStats(address)
              address && fetchCollectionInfo(address, e.detail.value)
            }}
          >
            {ownedCollections.map((x, i) => {
              const collection = collections.find(
                (c) => c.address.toLowerCase() === x.address.toLowerCase(),
              )!
              if (!collection) {
                return (
                  <Fragment key={x.address}>
                    {/* <IonButton
                      href={'https://ftmscan.com/address/' + x.address}
                    >
                      {x.address}
                    </IonButton> */}
                  </Fragment>
                )
              }
              return (
                <IonAccordion key={x.address} value={x.address}>
                  <IonItem slot="header">
                    <IonAvatar>
                      <IonImg src={collection.thumbnail} />
                    </IonAvatar>
                    <IonTitle>
                      {x.total} {collection.name}{' '}
                      {flavorText[collection.name] || ''}
                    </IonTitle>
                  </IonItem>
                  <IonGrid slot="content">
                    <IonRow>
                      {inventory[x.address] ? (
                        inventory[x.address].map((x, i) => (
                          <IonCol
                            key={i}
                            sizeSm={ '2'
                            }
                          >
                            <IonCard>
                              <IonItem color={'tertiary'}>
                                <IonButtons slot="start">
                                  <IonCardTitle>
                                    {x.name ||
                                      (fallback[address + '-' + x.tokenId] &&
                                        fallback[address + '-' + x.tokenId]
                                          .name)}
                                  </IonCardTitle>
                                </IonButtons>
                                <IonButtons slot="end">
                                  <IonBadge color="darl">{x.tokenId}</IonBadge>
                                </IonButtons>
                              </IonItem>
                              <IonCardContent>
                                <NFTImage
                                  src={
                                    x.image ||
                                    x.thumbnail ||
                                    getFallbackImage(x.address, x.tokenId)
                                  }
                                />
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                        ))
                      ) : (
                        <IonCard>
                          <IonProgressBar type="indeterminate" />
                        </IonCard>
                      )}
                    </IonRow>
                    {/* <IonButton
                      expand="full"
                      color="tertiary"
                      href={'/create/' + x.address}
                    >
                      Create Pool
                    </IonButton> */}
                  </IonGrid>
                </IonAccordion>
              )
            })}
          </IonAccordionGroup>
        </IonCardContent>
      </IonCard>
    </IonContent>
  )
}
