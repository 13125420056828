import { BigNumber, Signer } from 'ethers'
import { create } from 'zustand'
import { UniswapPair } from '../types/ethers-contracts/UniswapPair'
import { UniswapPair__factory } from '../types/ethers-contracts/factories/UniswapPair__factory'
import { formatEther } from 'ethers/lib/utils.js'

export type UniswapPairTemplate = {
  instances: Record<string, UniswapPair>
  tokens: Record<string, [string, string]>
  reserves: Record<string, [BigNumber, BigNumber, number]>
  ftmPrice: () => BigNumber
  potionPriceUsd: () => number
  potionPrice: () => BigNumber
  initialize: (signer: Signer) => void
}
export const FtmUSDCLPAddress = '0x2543EAD1e0422c63F79061aAEB2A5818F6ee63E5'
export const FtmPotionLPAddress = '0xfe9e29bc931a741ecb745281865e7b98b7b1817f'
export const MimPotionLPAddress="0x6C7AE1CF25fE19b76db6B8ff1f50e411c33DAA2D"
export const usePotionOracle = create<UniswapPairTemplate>((set, store) => ({
  instances: {},
  tokens: {},
  reserves: {},
  ftmPrice: () => {
    const info = store().reserves[FtmUSDCLPAddress] || [
      BigNumber.from(1),
      BigNumber.from(0),
    ]
    const price = info[1].div(info[0]).mul(BigNumber.from(10).pow(6))
    if (price.eq(1)) {
      return BigNumber.from(1)
    }
    return price
  },
  potionPrice: () => {
    const info = store().reserves[FtmPotionLPAddress] || [
      BigNumber.from(1),
      BigNumber.from(1),
    ]
    console.log('Potion FTM Reserves', store().reserves[FtmPotionLPAddress])
    const price = info[1].mul(BigNumber.from(10).pow(18)).div(info[0])
    if (price.eq(0)) {
      return BigNumber.from(0)
    }
    return price
  },
  potionPriceUsd: () => {
    const potion = store().potionPrice()
    const ftm = store().ftmPrice()

    return parseFloat(formatEther(potion)) / parseFloat(formatEther(ftm))
  },
  initialize: (signer) => {
    if (!store().instances[FtmUSDCLPAddress]) {
      const usdCpairInstance = UniswapPair__factory.connect(
        FtmUSDCLPAddress,
        signer,
      )
      set({
        instances: {
          ...store().instances,
          [FtmUSDCLPAddress]: usdCpairInstance,
        },
      })
      usdCpairInstance.getReserves().then((reserves) => {
        console.log(reserves)
        set({ reserves: { ...store().reserves, [FtmUSDCLPAddress]: reserves } })
      })
    }
    const address = FtmPotionLPAddress
    const pairInstance = UniswapPair__factory.connect(address, signer)
    set({ instances: { ...store().instances, [address]: pairInstance } })
    pairInstance.getReserves().then((reserves) => {
      set({ reserves: { ...store().reserves, [address]: reserves } })
      console.log(reserves, 'NICE')
    })
  },
}))
