import { BigNumber, Signer } from 'ethers'
import { create } from 'zustand'
import { MasterChef } from '../types/ethers-contracts/MasterChef'
import { MasterChef__factory } from '../types/ethers-contracts/factories/MasterChef__factory'
export type MasterChefTemplate = {
  instance: MasterChef | undefined
  potionPerSecond: BigNumber | undefined
  poolInfo: Record<string, [string, BigNumber, BigNumber, BigNumber]>
  userInfo: Record<string, [BigNumber, BigNumber]>
  pendingPotion: Record<string, BigNumber>
  initialize: (signer: Signer, address: string) => void
}

export const useMasterChef = create<MasterChefTemplate>((set, store) => ({
  instance: undefined,
  userInfo: {},
  poolInfo: {},
  pendingPotion: {},
  potionPerSecond: undefined,
  initialize: (signer, address) => {
    if (!signer) {
      return
    }
    const contractInstance = MasterChef__factory.connect(
      '0x962F8eD9cF4fE67962c9CF110410eec4828b2291',
      signer,
    )
    set({ instance: contractInstance })
    contractInstance.pendingPOTION(0, address).then((pending) => {
      set({ pendingPotion: { ...store().pendingPotion, '0': pending } })
    })
    contractInstance.pendingPOTION(1, address).then((pending) => {
      set({ pendingPotion: { ...store().pendingPotion, '1': pending } })
    })
    contractInstance.userInfo(BigNumber.from(0), address).then((res) => {
      set({ userInfo: { ...store().userInfo, '0': res } })
    })
    contractInstance.userInfo(BigNumber.from(1), address).then((res) => {
      set({ userInfo: { ...store().userInfo, '1': res } })
    })
    contractInstance.poolInfo('1').then((res) => {
      set({ poolInfo: { ...store().poolInfo, '1': res } })
    })
    contractInstance.poolInfo('0').then((res) => {
      set({ poolInfo: { ...store().poolInfo, '0': res } })
    })
    if (typeof store().potionPerSecond === 'undefined')
      contractInstance.potionPerSecond().then((res) => {
        set({ potionPerSecond: res })
      })
  },
}))
