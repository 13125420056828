import { fetchBalance } from '@wagmi/core'
import { BigNumber, Signer, ethers } from 'ethers'
import { create } from 'zustand'
import { Erc20__factory } from '../types/ethers-contracts/factories/Erc20__factory'
import { PairERC20__factory } from '../types/ethers-contracts/factories/PairERC20__factory'
import { Pair__factory } from '../types/ethers-contracts/factories/Pair__factory'
import { Pair } from '../types/ethers-contracts/Pair'
import { PairERC20 } from '../types/ethers-contracts/PairERC20'

export type PairInfo = [number, BigNumber, BigNumber, BigNumber, BigNumber]
export type PairTemplate = {
  instance: Record<string, Pair | PairERC20>
  sellQuote: Record<string, PairInfo>
  buyQuote: Record<string, PairInfo>
  fee: Record<string, BigNumber>
  spotPrice: Record<string, BigNumber>
  bondingCurve: Record<string, string>
  owner: Record<string, string>
  delta: Record<string, BigNumber>
  tokenBalance: Record<string, BigNumber>
  heldIds: Record<string, BigNumber[]>
  collection: Record<string, string>
  erc20: Record<string, string>
  poolType: Record<string, number>
  getSellPrice: (address: string) => BigNumber | undefined
  getBuyPrice: (address: string) => BigNumber | undefined
  initialize: (address: string, signer: Signer|ethers.providers.Provider) => void
}

export const usePair = create<PairTemplate>((set, store) => ({
  instance: {},
  buyQuote: {},
  sellQuote: {},
  bondingCurve: {},
  delta: {},
  fee: {},
  spotPrice: {},
  tokenBalance: {},
  owner: {},
  collection: {},
  erc20: {},
  poolType: {},
  heldIds: {},
  getBuyPrice: (address) => {
    return store().buyQuote[address] && store().buyQuote[address][3]
  },
  getSellPrice: (address) => {
    return store().sellQuote[address] && store().sellQuote[address][3]
  },
  initialize: (a, signer) => {
    const address = a.toLowerCase()
    const instance = Pair__factory.connect(address, signer)
    const Erc20Instance = PairERC20__factory.connect(address, signer)
    instance.owner().then((ownedBy) => {
      set({
        owner: {
          ...store().owner,
          [address]: ownedBy,
        },
      })
    })
    instance.getBuyNFTQuote(1).then((itemInfo) => {
      set({
        buyQuote: {
          ...store().buyQuote,
          [address]: itemInfo,
        },
      })
    })

    instance.fee().then((feeInfo) => {
      set({
        fee: {
          ...store().fee,
          [address]: feeInfo,
        },
      })
    })

    instance.delta().then((deltaAmount) => {
      set({
        delta: {
          ...store().delta,
          [address]: deltaAmount,
        },
      })
    })

    instance.spotPrice().then((spotPrice) => {
      set({
        spotPrice: {
          ...store().spotPrice,
          [address]: spotPrice,
        },
      })
    })
    instance.getSellNFTQuote(1).then((itemInfo) => {
      set({
        sellQuote: {
          ...store().sellQuote,
          [address]: itemInfo,
        },
      })
    })
    instance.getAllHeldIds().then((heldIds) => {
      set({
        heldIds: {
          ...store().heldIds,
          [address]: heldIds,
        },
      })
    })
    instance.bondingCurve().then((bondingCurve) => {
      set({
        bondingCurve: {
          ...store().bondingCurve,
          [address]: bondingCurve,
        },
      })
    })
    instance.poolType().then((poolTypeEnum) => {
      set({
        poolType: {
          ...store().poolType,
          [address]: poolTypeEnum,
        },
      })
    })
    Erc20Instance.token()
      .then((tokenAddress: string) => {
        set({
          erc20: {
            ...store().erc20,
            [address]: tokenAddress,
          },
        })
        Erc20__factory.connect(tokenAddress, signer)
          .balanceOf(address)
          .then((erc20Balance) => {
            set({
              tokenBalance: {
                ...store().tokenBalance,
                [address]: erc20Balance,
              },
            })
          })
        set({ instance: { ...store().instance, [address]: Erc20Instance } })
      })
      .catch((error) => {
        fetchBalance({ address: address as any, chainId: 250 }).then(
          (ftmBalance) => {
            set({
              tokenBalance: {
                ...store().tokenBalance,
                [address]: ftmBalance.value,
              },
            })
          },
        )
        set({
          erc20: {
            ...store().erc20,
            [address]: 'fantom',
          },
          instance: { ...store().instance, [address]: instance },
        })
      })

    instance.nft().then((collectionAddress) => {
      set({
        collection: { ...store().collection, [address]: collectionAddress },
      })
    })
  },
}))
